<template>
    <el-container class="acc_container">
   <!-- 页面主体区域 -->
   <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/userManage' }">账户管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-main>
            <el-divider class="divi"></el-divider>

            <el-form :inline="true">
                <el-form-item>
                    <el-button type="primary" @click="addDialogVisible=true">新建账户</el-button>
                    <el-button v-if="setting.batchRemove" type="danger" :disabled="multipleSelection.length==0" @click="deleteMany">批量删除</el-button>
                </el-form-item>
                <el-form-item>
                    <el-input placeholder="请输入用户名" v-model="queryByName"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="queryUserByName()">查询</el-button>
                </el-form-item>
               <span v-if="setting.advancesearch" style="font-size: 10px;">用户状态：</span>
                <el-select v-if="setting.advancesearch" v-model="status" placeholder="请选择" @change="queryByStatus()">
                    <el-option v-for="item in options" :key="item.status" :label="item.label" :value="item.status">
                    </el-option>
                </el-select>
            </el-form>
            <el-card>
                <el-table :data="tableData" @selection-change="handleSelectionChange" style="width: 100%" border stripe>
                    <el-table-column v-if="setting.batchRemove"  type="selection" header-align="center" align="center" width="50">
                    </el-table-column>
                    <el-table-column prop="id" label="用户ID" header-align="center" align="center" width="180">
                    </el-table-column>
                    <el-table-column prop="name" label="用戶名" header-align="center" align="center" width="180">
                    </el-table-column>
                    <el-table-column prop="department" label="用户部门" header-align="center" align="center" width="180">
                    </el-table-column>
                    <el-table-column prop="registdate" label="注册时间" header-align="center" align="center" width="220">
                    </el-table-column>
                    <el-table-column prop="enabled" label="是否可用" header-align="center" align="center" width="220">
                         <template slot-scope="scope">
                                <el-tag v-if="scope.row.enabled == 0" size="small" type="danger">禁用</el-tag>
                                <el-tag v-else size="small">正常</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" header-align="center" align="center" width="180">
                        <template slot-scope="scope">
                            <el-button  type="text" size="small" @click="UpdateUser(scope.row)">修改</el-button>
                            <el-button  type="text" size="small" @click="deleteUser(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                  <el-table-column prop="telephone" label="手机号" header-align="center" align="center" width="180">
                  </el-table-column>
                </el-table>

<!-- 分页区域 -->
<!--<el-pagination @size-change="handleSizeChange"
               @current-change="handleCurrentChange"
               :current-page="currentPage4"

               :page-size="1"
               layout="total, sizes, prev, pager, next, jumper"
               :total="100">
</el-pagination>-->
                <div style="display: flex;justify-content: flex-end">
                    <el-pagination
                            background
                            @current-change="currentChange"
                            @size-change="sizeChange"
                            :current-page="page"
                            :page-size="size"
                            layout="sizes, prev, pager, next, jumper, ->, total, slot"
                            :total="total">
                    </el-pagination>
                </div>
</el-card>

<!-- 添加用户的表单 -->
<el-dialog title="新增用户" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
    <!-- 内容主体区域 -->
    <el-form ref="addUserFormRef" :model="addUserForm" label-width="70px" :rules="addUserFormRules">
        <el-form-item label="用户名" prop="name">
            <el-input v-model="addUserForm.name" placeholder="登录帐号"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
            <el-input v-model="addUserForm.password" type="password" placeholder="密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="comfirmPassword">
            <el-input v-model="addUserForm.comfirmPassword" type="password" placeholder="确认密码"></el-input>
        </el-form-item>

        <el-form-item label="用戶部门" prop="department">
            <el-input v-model="addUserForm.department" placeholder="用戶部门"></el-input>
        </el-form-item>
<!--        <el-form-item label="邮箱" prop="email">
            <el-input v-model="addUserForm.email" placeholder="邮箱"></el-input>
        </el-form-item>-->
        <el-form-item label="手机号" prop="telephone">
            <el-input v-model="addUserForm.telephone" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item label="角色" size="mini" prop="roleIdList">
            <el-checkbox-group v-model="addUserForm.roleIdList">
                <el-checkbox v-for="role in roleList" :key="role.roleId" :label="role.roleId">{{ role.roleName }}</el-checkbox>
            </el-checkbox-group>
        </el-form-item>
        <el-form-item label="状态" size="mini" prop="status">
            <el-radio-group v-model="addUserForm.enabled">
                <el-radio :label="false">禁用</el-radio>
                <el-radio :label="true">正常</el-radio>
            </el-radio-group>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="addDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addUser()">确 定</el-button>
  </span>
</el-dialog>

<!-- 修改用户的对话框 -->
<el-dialog title="修改用户" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
    <el-form ref="editUserFormRef" :model="editUserForm" label-width="100px">
        <el-form-item label="用户ID:">
            <el-input v-model="editUserForm.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="用戶名:">
            <el-input v-model="editUserForm.name"></el-input>
        </el-form-item>
        <el-form-item label="用户部门:">
            <el-input v-model="editUserForm.department"></el-input>
        </el-form-item>
        <el-form-item label="注册时间:">
            <el-input v-model="editUserForm.registdate"></el-input>
        </el-form-item>
        <el-form-item label="手机号码:">
            <el-input v-model="editUserForm.telephone"></el-input>
        </el-form-item>
        <el-form-item label="状态" size="mini" prop="status">
            <el-radio-group v-model="editUserForm.enabled">
                <el-radio :label="false">禁用</el-radio>
                <el-radio :label="true">正常</el-radio>
            </el-radio-group>
        </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="editDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="editUser()">确 定</el-button>
  </span>
</el-dialog>

</el-main>

</el-container>
</template>

<script>
    import {
        isEmail,
        isMobile
    } from '@/utils/validate'
    export default {
        data() {
            var validatePassword = (rule, value, callback) => {
                if (!this.addUserForm.id && !/\S/.test(value)) {
                    callback(new Error('密码不能为空'))
                } else {
                    callback()
                }
            }
            var validateComfirmPassword = (rule, value, callback) => {
                if (!this.addUserForm.id && !/\S/.test(value)) {
                    callback(new Error('确认密码不能为空'))
                } else if (this.addUserForm.password !== value) {
                    callback(new Error('确认密码与密码输入不一致'))
                } else {
                    callback()
                }
            }
            var validateEmail = (rule, value, callback) => {
                if (!isEmail(value)) {
                    callback(new Error('邮箱格式错误'))
                } else {
                    callback()
                }
            }
            var validateMobile = (rule, value, callback) => {
                if (!isMobile(value)) {
                    callback(new Error('手机号格式错误'))
                } else {
                    callback()
                }
            }
            return {
                multipleSelection:[],
                queryByName:'',
                page:1,  //當前第某頁
                size:10, //當前每頁頁數
                total:100, //全部總量
                input: '',
                options: [{
                    status: true,
                    label: '正常'
                }, {
                    status: false,
                    label: '禁用'
                }],
                tableData: [{
                        id: '100',
                        name: 'test',
                        department: '管理员',
                        registdate: '2021-05-13 08：50：08',
                        telephone: 15029958693,
                        enabled: true
                    }, {
                        id: '101',
                        name: 'test',
                        department: '普通用户',
                        registdate: '2021-05-14 08：34：08',
                        telephone: 15029958693,
                        enabled: true
                    }, {
                        id: '102',
                        name: 'test',
                        department: '管理员',
                        registdate: '2021-05-17 08：23：09',
                        telephone: 15043458693,
                        enabled: true
                    }, {
                        id: '103',
                        name: 'test',
                        department: '普通用户',
                        registdate: '2021-05-17 08：43：08',
                        telephone: 15029974293,
                        enabled: true
                    }
                ],
                editUserForm: {
                    id: '',
                    name:'',
                    department: '',
                    registdate: '',
                    telephone: 1,
                    enabled:true
                },
                status: null,
                addDialogVisible: false,
                editDialogVisible: false,
                // 添加用户的表单数据
                addUserForm: {
                    name: '',
                    password: '',
                    comfirmPassword: '',
                    department: '',
                    telephone: '',
                    roleIdList: [],
                    enabled:true
                },
                addUserFormRules: {
                    userName: [{
                        required: true,
                        message: '用户名不能为空',
                        trigger: 'blur'
                    }],
                    password: [{
                        validator: validatePassword,
                        trigger: 'blur'
                    }],
                    comfirmPassword: [{
                        validator: validateComfirmPassword,
                        trigger: 'blur'
                    }],
                    email: [{
                        required: true,
                        message: '邮箱不能为空',
                        trigger: 'blur'
                    }, {
                        validator: validateEmail,
                        trigger: 'blur'
                    }],
                    telephone: [{
                        required: true,
                        message: '手机号不能为空',
                        trigger: 'blur'
                    }, {
                        validator: validateMobile,
                        trigger: 'blur'
                    }]
                },
                roleList: [{
                    roleId: 0,
                    roleName: '管理员'

                }, {
                    roleId: 1,
                    roleName: '普通用户'

                }],
                setting:{
					batchRemove:true,
					advancesearch:true,
				
				},
                name:'账户管理  ',

            }
        },
        mounted(){
            this.initData();
            
			//根据名字查询系统设置中的结果	
			this.getRequest('/systemSetting/getSettingInfoByName/?name='+this.name).then(resp=>{
					if (resp){
						// console.log(this.name)
						// console.log(resp)
						this.setting.batchRemove = resp.batchremove;
						this.setting.advancesearch = resp.advancesearch;
						this.size = resp.numberperpage;
		
					}
				});
        },
        methods: {
            queryByStatus(){
                console.log(this.status);
                this.getRequest('/user/queryByStatus/?status='+this.status).then(resp =>{
                    if (resp){
                        this.tableData = resp;
                        this.total = resp.size;
                    }
                })
            },
            handleSelectionChange(val){
                this.multipleSelection = val;
            },
            currentChange(currentPage){
                this.page=currentPage;
                this.initData();
            },
            sizeChange(currentSize) {
                this.size = currentSize;
                this.initData();
            },
            deleteMany(){
                this.$confirm('此操作将永久删除【' + this.multipleSelection.length + '】条记录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() =>{
                    let ids = '?';
                    this.multipleSelection.forEach(item => {
                        ids += 'ids=' + item.id + '&';
                    });
                    this.deleteRequest('/user/'+ids).then(resp =>{
                        if (resp){
                            this.initData();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            queryUserByName(){
              console.log(this.queryByName);
              this.getRequest('/user/queryByUsername/?'+'name='+this.queryByName).then(resp =>{
                  if (resp){
                      this.total = resp.size;
                      this.tableData = resp;
                  }
              })
            },
            editUser(){
                this.putRequest('/user/updateUser',this.editUserForm).then(resp => {
                    if (resp){
                        this.initData();
                        this.editDialogVisible = false;
                    }
                })
            },
            addUser(){

                delete this.addUserForm.roleIdList;
                delete this.addUserForm.comfirmPassword;
                console.log(this.addUserForm);
                this.postRequest('/user/addUser',this.addUserForm).then(resp => {
                    if (resp){
                        this.initData();
                        this.addDialogVisible = false;
                    }
                });
            },

            // 监听 pagesize 改变的事件
            handleSizeChange(newSize) {
                console.log(newSize)
            },
            //监听 页码值 改变的事件
            handleCurrentChange(newPage) {
                console.log(newPage)
            },
            // 展示编辑用户用的对话框
            UpdateUser(row) {
                console.log(row);
                this.editDialogVisible = true;
                this.editUserForm.telephone = row.telephone;
                this.editUserForm.registdate = row.registdate;
                this.editUserForm.id = row.id;
                this.editUserForm.name = row.name;
                this.editUserForm.department = row.department;
                this.editUserForm.enabled = row.enabled
            },
            // 根据id删除对应的用户信息
            deleteUser(id) {
                //弹框询问用户是否删除组件
                this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log('删除成功!');
                    this.deleteRequest('/user/deleteUserById/'+id).then(resp =>{
                        if (resp){
                            this.initData();
                        }
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },

            // 监听添加用户对话框的关闭事件
            addDialogClosed() {
                this.$refs.addUserFormRef.resetFields()
            },
            //监听修改用户对话框的关闭事件
            editDialogClosed() {
                this.$refs.editUserFormRef.resetFields()

            },
            initData(){
                //这里的数据没有进行初始化
                this.getRequest('/user/getUserByPage/?page=' + this.page + '&size='+this.size).then(resp=>{
                    if (resp){
                        this.tableData = resp.data;
                        this.total = resp.total;
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .acc_container {
        height: 100%;
    }

    .divi {
        display: block;
        height: 1px;
        width: 100%;
        margin: 24px 0;
        background-color: #dcdfe6;
        position: relative;
    }

    .el-header {
        background-color: #444C5B;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #ffffff;
        font-size: 16px;
        font-family: '微软雅黑';
        cursor: pointer;
    }

    .el-header .title {
        font-size: 22px;
    }

    .exit .el-icon-user-solid {
        margin-right: 5px;
    }

    .modify {
        color: #3FB2FB;
        margin-right: 10px;
    }

    .delete {
        color: #FF5F5F;
    }

    .el-pagination {
        margin-top: 15px;
    }
</style>
